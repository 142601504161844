import React from 'react'

import Layout from '../components/layout'
import Email from '../components/shared/email'

const Forbidden: React.FC<any> = () => {
  return (
    <Layout tocEnabled={false}>
      <h1>Something is wrong here!</h1>
      This is a 403 error, which means your permissions are not sufficient to
      access this page. If you think you receive this message by an error,
      please contact <Email>falk.hassler@uwr.edu.pl</Email>.
    </Layout>
  )
}

export default Forbidden
